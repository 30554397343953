
































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'SupportList'
})

export default class extends Vue {
  private baseUrl = '/static/files/'
  private commonUrl = UserModule.officeId + '/1.%20entypa%20-%20entoles/'
  private imageGroups = [{
    title: 'promotionalMaterial',
    url: `${this.baseUrl}${UserModule.officeId}/2.%20yliko%20prowthhshs/`,
    items: [
      'ForRent2.jpg',
      'ForSale2.jpg',
      'LETTERHEAD2.jpg',
      'NEWSLETTER2.jpg'
    ]
  }, {
    title: 'logos',
    url: `${this.baseUrl}${UserModule.officeId}/3.%20logos/`,
    items: [
      'LOGO.jpg',
      'OFFICE CARD.jpg',
      'OFFICE LOGO.jpg'
    ]
  }, {
    title: 'logosForEmpty',
    url: `${this.baseUrl}eikonidiaGiaAkinhta/`,
    items: [
      'diamerismata.jpg',
      'grafeiaKsenod.jpg',
      'katasthmata.jpg',
      'monokatikiaMezoneta.jpg',
      'oikopedaAgrotemaxia.jpg'
    ]
  }]

  get isSuperAdmin() {
    return this.hasRole('SUPER_ADMIN')
  }

   link(name:string){
    return '/api/files/getFile?filename='+name;
  }

  get isBroker() {
    return this.hasRole('BROKER')
  }

  get isSecretariat() {
    return this.hasRole('SECRETARIAT')
  }

  get isCollaborator() {
    return this.hasRole('COLLABORATOR')
  }

  get officeId() {
    return UserModule.officeId
  }

  get officeBeeId() {
    return UserModule.office.bee_id
  }

  private hasRole(name: string) {
    return UserModule.roles.findIndex((role: any) => {
      return role.name === name
    }) !== -1
  }
}
